.about {
  background-color: #0d0d0d;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: 'ABOUT';
    font-weight: bold;
    position: absolute;
    color: #000000;
    font-family: 'Bebas Neue';
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
    letter-spacing: 0.3em;
    margin-left: 0.15em;
    line-height: 1;
    margin-bottom: 0.15em;
    font-size: 100px;

    @include mq(desktop) {
      margin-bottom: -0.15em;
      font-size: 400px;
    }
  }
}

.about__content {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  transform: translateY(5%);
}

$space: 1rem;

.about__list{
  width: 100%;
  display: inline-block;
  float: left;
  padding: 70px 25px 0px;
  overflow-y: auto;
  @include mq(desktop) {
    width: 50%;
    overflow-x: visible;
    padding: 160px 58px 180px 0;
  }
}

.list_2 {
  padding: 0 25px 60px;
  @include mq(desktop) {
    overflow-x: visible;
    padding: 160px 90px 180px 0;
  }
}

.about__item {
  width: 100%;
  flex: 0 0 260px;
  color: #ffffff;
  @include mq(desktop) {
    flex: 0 0 calc(50%);
  }
}

.about__item-text {
  font-size: 10px;
  margin-bottom: 25px;
  @include mq(desktop) {
    font-size: 13px;
    line-height: 26px;
    margin-bottom: 50px;
  }
}

.activity {
  font-family: 'Bebas Neue';
  font-size: 26px;
  line-height: 33px;
  text-transform: uppercase;
  color: #cda558;
  @include mq(desktop) {
  }
}

.about__line-bot {
  position: absolute;
  bottom: -13px;
  width: 100%;
  height: 2px;
  background-color: #262626;
  @include mq(desktop) {
    display: block;
    bottom: -25px;
  }
}
