.home {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  background-image: url('/assets/img/bg_mobile.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 30px;

  @include mq(desktop) {
    padding: 0;
    background-image: url('/assets/img/bg.png');
  }
}

.home__wrap {
  max-width: 1280px;
  margin: 0 auto;

  @include mq(desktop) {
    padding: 0 100px;
  }
}

.home__content {

  @include mq(desktop) {
    width: 50%;
    margin-left: 50%;
    transform: translateY(50%);
  }
}

.home__title {
  font-family: 'Bebas Neue';
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  position: relative;

  @include mq(desktop) {
    display: flex;
  }
}

.home__title-company {
  color: $gold;
  font-size: 40px;
  display: inline-block;
  margin-bottom: 20px;

  @include mq(desktop) {
    font-size: 80px;
    margin-right: 35px;
    position: absolute;
    right: 100%;
    margin-top: 0.1em;
    margin-bottom: 0;
  }
}

.home__title-memo {
  font-size: 70px;
  margin-bottom: 0.1em;
  position: relative;

  @include mq(desktop) {
    font-size: 160px;
  }
}

.home__lines {
  display: block;
}

.home__lines-top {
  width: 85%;
  height: 51px;
  margin-bottom: 38px;
  display: block;
  position: absolute;
  bottom: 100%;
  display: none;
  @include mq(desktop) {
    display: block;
  }
}

.home__line-bot {
  position: absolute;
  left: 50%;
  bottom: 12px;
  width: 2px;
  height: 51px;
  background-color: $gold;
  display: none;

  @include mq(desktop) {
    display: block;
  }
}

.home__lead {
  font-size: 14px;
  line-height: 1.71;
  color: #ffffff;
}

.home__decor {
  position: absolute;
  padding: 30px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 85px;
  overflow: hidden;

  @include mq(desktop) {
    display: none;
  }
}

.home__decor-lines {
  display: block;
  margin-right: 35px;
  width: 242px;
}

.home__decor-phone {
  background-color: #322a1a;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  flex: 0 0 36px;
  display: block;
  padding: 8px;
  text-align: center;
}

.switchable-elements {
  position: relative;
}

.switchable-elements__item {
  transition: all .25s ease;
  opacity: 1;
  visibility: visible;

  &.is-disabled {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
}
