.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 60px 100px;
  display: none;
  @include mq(desktop) {
    display: block;
  }
}

.footer__menu {
  text-align: right;
}

.footer__menu-list {
  list-style: none;
}

.footer__menu-link {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  &:hover {
    color: #fff;
  }
}