
/**************************\
  Basic Modal Styles
\**************************/

.modal {
  font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #322a1a;
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 100;
}

.modal__container {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 30px 0;

  @include mq(desktop) {
    padding: 45px;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 60px;
  @include mq(desktop) {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
  color: #fff;
  font-family: 'Bebas Neue';
  box-sizing: border-box;
  margin-bottom: -.2em;

  @include mq(desktop) {
    font-size: 80px;
  }
}

.modal__close {
  position: absolute;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='73' height='73'%3E%3Cpath fill-rule='evenodd' fill='%23CDA558' d='M73.008 2.478L38.973 36.513l34.021 34.022-2.472 2.473L36.5 38.986 2.478 73.008.006 70.535l34.022-34.022L-.008 2.478 2.465.006 36.5 34.041 70.535.006l2.473 2.472z'/%3E%3C/svg%3E");
  right: 30px;
  top: 30px;
  min-width: 32px;
  min-height: 32px;
  z-index: 1;

  @include mq(desktop) {
    right: 45px;
    top: 45px;
    width: 36px;
    height: 36px;
  }
}

.modal__content {
  margin-top: 3rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
  padding: 0 30px;
  @include mq(desktop) {
    padding: 0;
  }
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
