*, ::after, ::before {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: 'Google Sans', 'sans-serif';
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  text-align: left;
  background-color: #0d0d0d;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

p {
  margin-top: 0;
}

.fadeInEffect {
  opacity: 0;
  transform: translateY(100%)
}

.slideInDown {
  transform: translateY(-100%)
}

.slideInTop {
  transform: translateY(100%)
}

.animatatedText {
  display: inline-flex;
  overflow: hidden;
  span {
    transform: translateY(100%);
    opacity: 0;
  }
}
