@font-face {
  font-family: 'Google Sans';
  src: url('/assets/fonts/GoogleSans-Regular.woff2') format('woff2'),
      url('/assets/fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/BebasNeueBold.woff2') format('woff2'),
      url('/assets/fonts/BebasNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/BebasNeueRegular.woff2') format('woff2'),
      url('/assets/fonts/BebasNeueRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
