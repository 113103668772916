.team {
  background-color: #0d0d0d;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: 'TEAM';
    font-weight: bold;
    position: absolute;
    color: #000000;
    font-family: 'Bebas Neue';
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
    letter-spacing: 0.4em;
    margin-left: 0.15em;
    line-height: 1;
    margin-bottom: 0.15em;
    font-size: 100px;

    @include mq(desktop) {
      margin-bottom: -0.15em;
      font-size: 400px;
    }
  }
}

.team__content {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

$space: 1rem;

.team__list {
  display: flex;
  margin-left: -$space;
  margin-right: $space;
  padding: 120px 30px 160px;
  overflow-x: auto;

  @include mq(desktop) {
    margin-left: -$space;
    margin-right: -$space;
    overflow-x: visible;
    padding: 160px 0 100px;
  }
}

.team__item {
  margin: 0 $space;
  flex: 0 0 260px;
  color: #ffffff;
  @include mq(desktop) {
    flex: 0 0 calc(33.33% - #{$space * 2});
  }
}

.team__item-img {
  max-width: 100%;
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;

  @include mq(desktop) {
    margin-bottom: 35px;
    width: 230px;
  }
}

.team__item-name {
  font-size: 30px;
  font-family: 'Bebas Neue';
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;

  @include mq(desktop) {
    font-size: 40px;
  }
}

.team__item-position {
  font-family: 'Bebas Neue';
  font-weight: 400;
  line-height: 1;
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 24px;

  @include mq(desktop) {
    font-size: 30px;
  }
}

.team__item-text {
  font-size: 10px;
  margin-bottom: 35px;
  @include mq(desktop) {
    font-size: 13px;
    margin-bottom: 45px;
  }
}

.team__item-links {
  display: flex;
}

.team__item-link {
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.team__item-icon {
  width: 20px;
}
