.contacts {
  .contact {
    background-color: #0d0d0d;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('/assets/img/map2.png');
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    padding: 0 30px;
    display: flex;
    align-items: flex-end;
    @include mq(desktop) {
      display: block;
      padding: 0;
    }
  }
}
.contacts__content {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  padding: 120px 0 30px;
  @include mq(desktop) {
    padding: 170px 0 170px;
  }
}

.contacts__email {
  color: $gold;
  font-size: 35px;
  margin-bottom: -.2em;
  font-family: 'Bebas Neue';

  @include mq(desktop) {
    font-size: 60px;
  }
}

.contacts__address {
  font-size: 30px;
  color: #fff;
  margin-bottom: -.2em;
  font-family: 'Bebas Neue';

  @include mq(desktop) {
    font-size: 40px;
  }
}

.contacts__target {
  position: absolute;
  width: 84px;
  height: 84px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 2;

  @include mq(desktop) {
    width: 168px;
    height: 168px;
  }
}

.contacts__street {
  position: absolute;
  z-index: 3;
  left: 15px;
  right: 0;
  bottom: 80px;
  top: 0;
  margin: auto;
  color: #fff;
  width: 180px;
  height: 110px;
  transform: translate3d(50%,50%,0);
  font-size: 30px;
  line-height: 1;
  padding: 1rem;
  font-family: 'Bebas Neue';

  @include mq(desktop) {
    font-size: 30px;
    left: 0;
    bottom: 0;
  }
}

.contacts__phone {
  position: absolute;
  right: 30px;
  bottom: 45px;
  background-color: #322a1a;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  padding: 8px;

  @include mq(desktop) {
    display: none;
  }
}
