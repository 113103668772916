@import "_mq.scss";

@mixin hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  &::-webkit-scrollbar { /* WebKit */
    width: 0px;
    display: none;
  }
}

$gold: #cda558;

// @import "bootstrap";
@import "normalize";
@import "base";

@import "fonts";

// Components
@import "components/modal";

// Partials
@import "partials/header";
@import "partials/footer";
@import "partials/home";
@import "partials/team";
@import "partials/career";
@import "partials/contacts";
@import "partials/about";
