.careers {
  background-color: #0d0d0d;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  .header {
    background: inherit;
  }

  &:before {
    content: 'WORK';
    text-transform: uppercase;
    font-weight: bold;
    position: absolute;
    color: #000000;
    font-family: 'Bebas Neue';
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
    letter-spacing: 0.4em;
    margin-left: 0.15em;
    line-height: 1;
    margin-bottom: 0.15em;
    font-size: 100px;

    @include mq(desktop) {
      margin-bottom: -0.15em;
      font-size: 400px;
    }
  }
}

.careers__item {
  color: #ffffff;
  display: flex;
  padding: 40px 0;
  cursor: pointer;
  h2 {
    cursor: pointer;
  }
  &:not(:last-child) {
    border-bottom: 2px solid #322a1a;
  }
}

.careers__item-code {
  font-size: 9px;
  margin-right: 25px;
  @include mq(desktop) {
    font-size: 14px;
    margin-right: 70px;
  }
}

.careers-content__column {
  @include mq(desktop) {
    width: 50%;
  }
}

.careers__item-title {
  font-size: 35px;
  font-weight: bold;
  font-family: 'Bebas Neue';
  line-height: 1;
  margin-bottom: -0.2em;
  @include mq(desktop) {
    font-size: 80px;
  }
}

.careers__content {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  max-height: 100vh;
  margin: 0 auto;
  width: 100%;
  overflow-y: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 30px;
  padding-right: 30px;

  @include hide-scrollbar;

  @include mq(desktop) {
    padding-top: 170px;
    padding-bottom: 170px;
    padding-left: 0;
    padding-right: 0;
  }
}

.careers-content__title {
  &:before {
    content: attr(data-code);
    font-size: 9px;
    text-transform: uppercase;
    color: #ffffff;
    vertical-align: top;
    margin-right: 20px;

    @include mq(desktop) {
      margin-right: 0;
      position: absolute;
      right: 99%;
      top: 45px;
      font-size: 14px;
    }
  }
}

.careers-content__content {
  color: #ffffff;
  position: relative;
}

.careers-content__lead {
  color: rgba(#fff, .2);
  max-width: 400px;
  font-size: 12px;
  margin-bottom: 25px;
  @include mq(desktop) {
    margin-bottom: 40px;
    font-size: 14px;
  }
}

.careers-content__items {
  @include mq(desktop) {
    display: flex;
  }
  margin-bottom: 0px;
}

.careers-content__subtitle {
  margin-bottom: 10px;
  font-family: 'Bebas Neue';
  font-size: 30px;
  line-height: 1;
  display: flex;

  &::after {
    content: '+';
    font-size: 15px;
    text-align: center;
    background-color: #0d0d0d;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    color: $gold;
    margin-left: auto;
    padding: 4px;
  }

  @include mq(desktop) {
    &::after {
      content: none;
    }
  }
}

.careers-content__list {
  padding-left: 1rem;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-right: 10px;
  display: none;
  @include mq(desktop) {
    font-size: 14px;
    display: block;
  }
}

.careers-content__apply {
  color: $gold;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Bebas Neue';

  @include mq(desktop) {
    font-size: 40px;
  }
}

.careers-content__block {
  padding-bottom: 2rem;
  &.is-expanded {
    .careers-content__subtitle {
      &::after {
        content: '-';
      }
    }
    .careers-content__list {
      display: block;
    }
  }
}
