.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 30px;
  z-index: 10;

  @include mq(desktop) {
    padding: 60px 100px;
  }
}

.header__burger {
  width: 21px;
  height: 21px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.header__menu {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #0d0d0d;
  transform: translate3d(-100%, 0, 0);
  transition: transform .25s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;

  body.show-menu & {
    transform: translate3d(0,0,0);
  }

  @include mq(desktop) {
    padding: 0;
    transform: translate3d(0,0,0);
    flex: 0 0 33.33%;
    position: static;
    background-color: transparent;
    display: block;
  }
}

.header__menu-close {
  position: absolute;
  top: 30px;
  right: 30px;
  svg {
    width: 16px;
    height: 16px;
  }

  @include mq(desktop) {
    display: none;
  }
}

.header__menu-list {
  margin: 0;
  padding: 30px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  @include mq(desktop) {
    padding: 0;
    flex-direction: row;
  }
}

.header__menu-item {
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #322a1a;
  }

  @include mq(desktop) {
    padding: 0;
    border-bottom: 0!important;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &.for-mobile {
    @include mq(desktop) {
      display: none;
    }
  }
}

.header__menu-link {
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  display: block;
  font-size: 30px;
  margin-bottom: -0.2em;

  @include mq(desktop) {
    display: inline-block;
    font-size: 12px;
    padding-bottom: 26px;
    font-family: inherit;
    &.is-current {
      border-bottom: 2px solid $gold;
    }
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.header__menu-phone {
  padding: 0 30px;
  justify-self: flex-end;
  color: #fff;
  @include mq(desktop) {
    display: none;
  }
}

.header__logo {
  flex: 0 0 33.33%;
  text-align: center;
}

.header__logo-img {
  width: 95px;
  height: 38px;
  @include mq(desktop) {
    width: 128px;
    height: 51px;
  }

  body.show-menu & {
    position: relative;
    z-index: 1;
  }
}

.header__phone {
  flex: 0 0 33.33%;
  text-align: right;
  display: none;

  @include mq(desktop) {
    display: block;
  }
}

.header__phone-icon {
  margin-right: 10px;
}

.header__phone-link {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  &:hover {
    color: #fff;
  }
}
